:root {
  --black: #000;
  --white: #fff;
  --orange: #ff8400;
  --green: rgb(0, 203, 132);
  --red: rgb(255, 0, 0);
  --grey: rgb(221, 221, 221);
}

body {
  /* background-color: rgb(247, 247, 253); */
  box-sizing: border-box;
  font-family: Manrope, sans-serif;
}

fieldset {
  border: 1px solid #109874;
  border-radius: 5px;
  padding: 20px;
}

legend {
  font-weight: 600;
  padding-right: 10px;
}

a {
  color: gray;
  text-decoration: none;
}

a:hover {
  color: #000;
}

.terms {
  font-size: 15px;
}

.line {
  background-color: #109874;
  width: 100%;
  height: 1px;
}

.underline {
  text-decoration: underline;
}

.white {
  color: var(--white)
}

/* FINGERPRINTS */
.hand {
  width: 100px;
  height: 100px;
}

.fingerprint-box {
  border-radius: 5px;
  background-color: #109874;
  border: 1px solid #109874;
  padding: 5px;
}

.fingerprint-box:hover {
  background-color: #126d55;
}

.fingerprint-icon {
  height: 35px;
  width: 35px;
}

.bold-1 {
  font-weight: 500;
}
.bold-2 {
  font-weight: 700;
}
.bold-3 {
  font-weight: 900;
}

.action {
  cursor: pointer;
}

.map-container {
  width: 100%;
  height: 400px;
}

#results {
  background-color: #ffffff;
  position: absolute;
  top: 60px;
  width: 350px;
  z-index: 1111;
}

.search-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.search-item:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.search-img {
  border-radius: 5px;
  width: 40px;
  height: 40px;
}

.wallet {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#109874b7), to(#109874));
  border-radius: 5px;
  color: #fff;
  padding: 60px 30px;
}

.wallet-side {
  background-color: #fff;
  padding: 30px 20px;
}

.navbar-toggler{
  background-color: #109874;
  color: #fff;
}

.navbar-toggler-icon{
  height: 3px;
}

.inner-list {
  list-style: none;
}

.select2-container--default .select2-selection--single {
  display: flex;
  align-items: center;
  height: 42px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: auto;
}

.disabled, .disabled:hover, .disabled:focus, .disabled:focus-visible, .disabled:active {
  color: #ddd;
  background-color: #bbb;
}

.cat-box {
  background-color: #109874;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
}

.remove{
  border: none;
  border-radius: 5px;
  background-color: rgb(255, 243, 243);
  width: fit-content;
}

.remove:hover{
  background-color: rgb(255, 224, 224);
}

.cart-number, .notification-number {
  align-items: center;
  background-color: #109874;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  height: 20px;
  justify-content: center;
  padding: 8px;
  width: 20px;
  left: 20px;
  top: 0px;
}

.signin {
    background-color: #109874;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    text-align: center;
    width: 80px;
}

.signin:hover {
  background-color: #0d8162;
  color: white;
}

/* REGISTER */
.preview, .avatar-top{
  border: 1px solid #109874;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

/* USER DETAILS */
.point-block {
  display: flex;
  align-items: center;
  border: 1px solid #0000002d;
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
}

/* ALL USERS */
.user-icon{
  height: 30px;
  width: 30px;
}

.avatar-top{
  height: 35px;
  width: 35px;
}

.custom-file-input{
  width: fit-content;
}

/* CHECKOUT STEPS */
.step {
  border: 0;
  color: gray;
  cursor: default;
  float: left;
  height: auto;
  letter-spacing: 1px;
  line-height: 30px;
  margin: 0;
  padding: 5px 10px 5px 15px;
  text-decoration: none;
}

.active-step{
  color: #fff;
  background-color: #109874;
}
.incomplete{
  background-color: #eee;
}
.triangle-active {
  float: left;
  border-top: 20px solid transparent;
  border-left: 15px solid #109874;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
  width: 0;
}

.triangle2-active {
  float: left;
  border-top: 20px solid #109874;
  border-left: 15px solid #ffffff;
  border-bottom: 20px solid #109874;
  margin-right: -1px;
  width: 0;
}

.triangle-incomplete {
  float: left;
  border-top: 20px solid transparent;
  border-left: 15px solid #eee;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
  width: 0;
}

.triangle2-incomplete {
  float: left;
  border-top: 20px solid #eee;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #eee;
  margin-right: -1px;
  width: 0;
}

/* LOGIN */
.white-logo {
  height: 38px;
  width: 150px;
}

.green-link {
  color: #109874;
  font-size: 0.9rem;
  font-weight: 600;
}

/* SELLER NAV */
.seller-section-box{
  margin-bottom: 60px;
}
.seller-nav-img{
  height: 18px;
  width: auto;
}

.user-nav-label {
  display: block;
  margin-top: 5px;
}

.user-nav-block {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 100%;
}

.user-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
}

/* SELLER DASHBOARD */
.weather-box {
  border-radius: 5px;
}

.dash-info-box{
  background-image: -webkit-gradient(linear, left top, left bottom, from(#109874b7), to(#109874));
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px #d6d6d66e;
  margin-bottom: 20px;
  padding: 40px 10px;
  width: 100%;
}

.dash-text-top, .dash-text-bottom {
  color: #fff;
}

.dash-text-top{
  font-size: 24px;
}

.dash-text-bottom{
  font-size: 12px;
  margin-top: 5px;
}

.vertical-divider{
  background-color: #1098744f;
  height: 100%;
  width: 2px;
}

.weather-divider{
  background-color: #71717166;
  width: 3px;
  height: 50px;
}

.icon-img{
  height: 20px;
  width: auto;
}

.table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.table-nav-block {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.table-nav{
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  margin-left: 16px;
}

.table-nav:hover{
  color: #0d8162;
}

.table-active{
  border-bottom: 3px solid #0d8162;
  color: #0d8162;
  font-weight: 600;
}

.date-table {
  border: 1px solid #109874;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

th, td {
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
  padding: 3px 10px;
}

td {
  font-weight: 500;
}

/* New Year header style */
.new-year-header {
  background-color: #109874;
  color: #fff;
}

.table-btn {
  background-color: #109874;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
}

.table-btn:hover {
  background-color: #0d8162;
  color: #fff;
}

/* CART */
.cart-qty-box{
  display: flex;
  justify-content: end;
  align-items: center;
}

.cart-text {
  font-size: 14px;
}

.cart-img{
  height: 50px;
  width: 50px;
}

.cart-img2{
  height: 60px;
  width: 60px;
}

.cart-img3{
  height: 40px;
  width: 40px;
}

.cart-img-box{
border-radius: 50px;
}

/* PAYMENT */
.payment-box {
  border: 1px solid #1098747a;
  border-radius: 5px;
}

/* PRODUCTS DETAILS PAGE */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.toggle-img{
  display: inline-block;
  border-radius: 5px;
  height: 45px;
  margin-top: 10px;
  width: 45px;
}

.toggle-img:hover {
  border: 2px solid #109874;
  background-color: #109874;
}

.img-div {
  display: flex;
  justify-content: center;
}

.details-img{
  border-radius: 5px;
  height: 190px;
  width: 200px;
}

.details-img2{
  height: 182px;
  width: 182px;
}

#count {
  border: none;
  font-size: 12px;
  text-align: center;
  width: 30px;
}

#count:focus-visible {
outline: 0px;
}


nav {
  background-color: white;
  border-bottom: 2px solid rgb(237, 237, 237);
}

.nav-label {
  color: #fff;
  background-color: #fa9c23;
  border-radius: 50px;
  font-size: 11px;
  font-weight: 600;
  padding: 5px 10px;
}

.bg-green{
  background-color: #109874;
}

/* LOADER */
.loader {
  display: block;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  padding-left: 0;
  width: 80px;
}

.loader::after{
  animation: lds-dual-ring 1.2s linear infinite;
  border: 6px solid #fa9c23;
  border-color: #fa9c23 transparent;
  border-radius: 50%;
  content: ' ';
  display: block;
  height: 64px;
  margin: 8px;
  width: 64px;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bg-white {
  background-color: white;
}

.weather-color{
  color: #109874;
}

.white-color {
  color: rgb(250, 250, 250);
}

.green-color {
  color: #109874;
}

.red-color {
  color: #ff0000;
}

.round-divider {
  font-size: 26px;
}

.mid-container{
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px rgb(203, 203, 203);
  padding: 60px 20px;
  padding-bottom: 30px;
  margin: 50px auto;
  margin-top: 30px;
}

.mid-container-header {
  padding: 0;
  margin-top: 40px;
  margin-bottom: 20px;
}
.text-size-xl {
  font-size: 22px;
}
.text-size-lg {
  font-size: 18px;
}
.text-size-md {
  font-size: 16px;
}
.text-size-sm {
  font-size: 14px;
}
.text-size-xs {
  font-size: 12px;
}
.text-size-xxs {
  font-size: 10px;
}
.red-circle {
  font-size: 8px;
}

/* CONFIRM ORDER */
.w-85{
  width: 85%;
}

.w-70{
  width: 70%;
}

.imgStyle {
  width: 100px;
  height: 100px;
  border: 3px solid grey;
}

/* NAV CSS */
.nav-link {
  color:  #000;
}

.search-form{
  margin: 0 100px;
  width: 75%;
}

.dropdown-menu[data-bs-popper] {
  border-top: 4px solid green;
  left: -75px;
  padding: 10px;
  top: 50px;
  width: 200px;
}

.dropdown-toggle::after {
	content: none;
}

.dropdown-toggle {
  border-radius:  50%;
  color: white;
  min-height: 40px;
  min-width: 40px;
  text-align: center;
}

.dropdown-item {
  color: rgb(48, 48, 48);
  margin: 10px 0;
}

hr {
  height: 20px;
}

hr, .green {
  color: #109874;
}

.light-hr{
  color: #10987482;
}

/* BUYER HERO SECTION */
.hero-section{
  background-size: cover;
  /* filter: brightness(50%); */
  height: 500px;
}

/* .hero-content {
  padding: ;
} */

.hero-header {
  color: white;
  font-weight: 700;
  font-size: 40px;
  width: 90%;
}

.white-btn {
  background-color: #fff;
}

.white-btn, .green-btn, .red-btn, .border-btn, .red-border-btn {
  border: none;
  border-radius: 5px;
  color: green;
  padding: 10px 18px;
  width: fit-content;
}

.white-btn:hover{
  color: white;
  background-color: #109874;
}

.green-btn, .border-btn {
  color: white;
  background-color: #109874;
  border: 2px solid #109874;
}

.red-btn {
  color: white;
  background-color: #ff0000;
  border: 2px solid #ff0000;
}

.green-btn:hover:enabled, .border-btn:hover {
  color: white;
  background-color: #0d8162;
}

.border-btn {
  background-color: #fff;
  border: 2px solid #109874;
  border-radius: 5px;
  color: #109874;
}

.red-border-btn{
  background-color: #fff;
  border: 2px solid #ff0000;;
  border-radius: 5px;
  color: #ff0000;
}

.red-btn:hover:enabled {
  color: white;
  background-color: #dc0000;
}

.border-btn:disabled {
  border: 2px solid gray;
  color: gray;
}

.green-btn:disabled:hover, .red-btn:disabled:hover, .border-btn:disabled:hover, .red-border-btn:disabled:hover{
  cursor: not-allowed;
}

.gray-btn{
  background-color: rgb(241, 241, 241);
  border: none;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.gray-btn:hover{
  background-color: rgb(230, 228, 228);
  color: #000;
}

.red-border-btn:hover{
  background-color: #ff0000;
  color: #fff;
}

.quantity-toggle {
  font-size: 16px;
  height: 25px;
  margin: 0;
  padding: 0;
  width:25px;
}

/* PRODUCT CARD */

.card {
  color: gray;
  height: 270px;
  border: none;
  border-radius: 0;
  padding: 10px;
  text-decoration: none;
  text-align: center;
  /* max-width: 200px; */
}

.card:hover {
  background-color: rgb(242, 242, 242);
}

.card-img-top{
  align-items: center;
  display: flex;
  height: 160px;
  justify-content: center;
  width: auto;

}

.card-name {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 3px;
  min-height: 40px;
}

.card-price {
  font-size: 15px;
  font-weight: 500;
}

.add-to-cart {
  align-items: center;
  background-color: rgb(213, 185, 0);
  border: none;
  border-radius: 5px;
  color: white;
  display: none;
  font-size: 22px;
  right: 10px;
  height: 35px;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  width: 35px;
}

.card-body:hover .add-to-cart{
  display: flex;
}

.add-to-cart:hover{
  background-color: rgb(247, 214, 0);
}

/* FOOTER CSS */
.footer-content {
  padding-top: 60px;
  padding-bottom: 60px;
}
.footer-header{
  color: white;
  font-size: 18px;
}

.footer-link{
  color: rgb(201, 201, 201);
  font-size: 14px;
  font-weight: 200;
  margin: 8px 0;
  text-decoration: none;
}

.footer-link:hover{
  color: rgb(255, 255, 255);
}

.footer-seperator{
  background-color: gray;
  height: 1px;
  margin-top: 80px;
  margin-bottom: 15px;
}

.copyright-text {
  color: rgb(201, 201, 201);
  font-weight: 200;
  font-size: 14px;
}

.plus {
  font-size: 25px;
  padding: 2px;
  margin-top: auto;
}

.breadcrumb {
  cursor: pointer;
}

.white-bg {
  background-color: #fff;
  padding: 50px 25px;
}

.green-bg {
  background-color: #109874;
  color: #fff;
}


@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
  .details-img{
    height: 190px;
    width: 200px;
  }

  .img-div {
    justify-content: start;
  }

  .user-nav-block {
    display: flex;
    padding: 40px, auto;
  }

  .dash-text-top {
    font-size: 28px;
  }
  .dash-text-bottom {
    font-size: 14px;
  }
  .plus {
    font-size: 12px;
    padding: 12px 18px;
  }

  th, td {
    font-size: 14px;
  }

  .mid-container{
    padding: 60px 40px;
  }

  .quantity-toggle {
    height: 30px;
    width:30px;
  }

  #count {
    width: 50px;
  }

  .text-size-lg {
    font-size: 20px;
  }

  .text-size-md {
    font-size: 18px;
  }
  .text-size-sm, #count {
    font-size: 14px;
  }

  .text-size-xs {
    font-size: 12px;
  }
  .text-size-xxs {
    font-size: 10px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
  .hero-header {
    font-size: 50px;
  }
  .details-img{
    height: 240px;
    width: 250px;
  }

  .dash-info-box{
    padding: 60px 20px;
  }

  .table {
    display: table;
  }
  .table-nav{
    font-size: 14px;
    margin-left: 24px;
  }
  .cart-img{
    height: 60px;
    width: 60px;
  }

  .text-size-xl {
    font-size: 30px;
  }

  .text-size-lg {
    font-size: 24px;
  }

  .text-size-md {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }

  .details-img{
    height: 260px;
    width: 280px;
  }

  .user-nav-block {
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .user-nav-item {
    padding: 0;
    padding-top: 5px;
    margin: 0;
  }

  .dash-info-box{
    width: 49%;
  }

  .white-bg{
    padding: 50px 40px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
  .mid-container {
    max-width: 80%;
  }
  .card {
    width: 100%;
  }
  #results {
    width: 530px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
  .mid-container{
    max-width: 65%;
  }
  #results {
    width: 712px;
  }
}
